
import { defineComponent, onMounted, ref, watch } from "@vue/runtime-core";
import {
  ErrorMessage,
  Field,
  Form,
  useResetForm,
  FormActions,
} from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getCustomersList,
  routingConfig,
  updateConfiguration,
} from "@/store/api/devices";
import { isNull } from "lodash";

interface Config {
  maximum_route_time: number;
  maximum_slack_time: number;
  se_account_id: string;
  optimal_routing: number;
  is_dynamic_route: number;
  routing_enabled: number;
  is_matrices_calculation: number;
}

export default defineComponent({
  name: "routing-configuration",
  props: {
    companyObject: { type: Object },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup(props) {
    const addroutingmodalRef = ref<null | HTMLElement>(null);
    const items = ref([]);
    const customer_list = ref([]);
    const companyObject_for_edit = ref<any>(props.companyObject);

    const payload = ref<Config>({
      maximum_route_time: 86399,
      maximum_slack_time: 1800,
      se_account_id: "",
      optimal_routing: 18000,
      routing_enabled: 1,
      is_dynamic_route: 0,
      is_matrices_calculation: 1,
    });

    onMounted(async () => {
      items.value.map((item) => ({ item, isEdit: false }));
      const feature = await getCustomersList();
      customer_list.value = feature;
    });
    watch(
      () => props.companyObject,
      (v) => {
        companyObject_for_edit.value = v;
        if (v?.id) {
          payload.value = companyObject_for_edit.value;
        } else {
          payload.value = {
            maximum_route_time: 86399,
            maximum_slack_time: 1800,
            se_account_id: "",
            optimal_routing: 18000,
            routing_enabled: 1,
            is_dynamic_route: 0,
            is_matrices_calculation: 1,
          };
        }
      }
    );
    const validationSchema = Yup.object().shape({
      maximum_route_time: Yup.number().required().label("Route Time"),
      se_account_id: Yup.string().required().label("Customer"),
      maximum_slack_time: Yup.number().required().label("Slack Time"),
      optimal_routing: Yup.number().required().label("Optimal Routing"),
      routing_enabled: Yup.number().required().label("Enable"),
      is_dynamic_route: Yup.number().optional().label("Enable"),
      is_matrices_calculation: Yup.number().optional().label("Enable"),
    });
    const submit = async () => {
      console.log("Data from Routing Config", payload.value);
      console.log("update", companyObject_for_edit.value.id);

      if (companyObject_for_edit.value.id) {
        console.log("Updated Data", payload.value);
        let res = updateConfiguration(
          companyObject_for_edit.value?.id,
          payload.value
        )
          .then((resp) => {
            Swal.fire({
              text: "Save Successfylly",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            document.getElementById("addnewnotificationmodal")?.click();
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
            document.getElementById("addnewnotificationmodal")?.click();
          });
      } else {
        console.log("Added Data", payload.value);
        let res = routingConfig(payload.value)
          .then((resp) => {
            console.log(resp);
            Swal.fire({
              icon: "success",
              title: `${resp.message}`,
            });
            document.getElementById("addnewnotificationmodal")?.click();
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
            document.getElementById("addnewnotificationmodal")?.click();
          });
      }
    };

    const modalClose = () => {
      companyObject_for_edit.value = "";
      payload.value = {
        maximum_route_time: 86399,
        maximum_slack_time: 1800,
        se_account_id: "",
        optimal_routing: 18000,
        routing_enabled: 1,
        is_dynamic_route: 0,
        is_matrices_calculation: 1,
      };
    };

    return {
      customer_list,
      payload,
      validationSchema,
      submit,
      addroutingmodalRef,
      companyObject_for_edit,
      modalClose,
    };
  },
});
